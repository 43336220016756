<template>
    <div class="q-summit-home-page-roadmap q-summit-home-page-roadmap--mobile" v-if="$vuetify.breakpoint.xs">
        <QSummitHomePageMountainsWithFlagsMobile />

        <div class="q-summit-home-page-roadmap__roadmap-wrapper">
            <QSummitHomePageRoadmapMobile  class="q-summit-home-page-roadmap__roadmap q-summit-home-page-roadmap__roadmap--mobile" />  
        </div>
    </div>
    <div class="q-summit-home-page-roadmap" v-else>
        <QSummitHomePageMountainsWithFlagsDesktop />

        <v-container class="q-summit-home-page-roadmap__roadmap-wrapper">
            <QSummitHomePageRoadmapDesktop class="q-summit-home-page-roadmap__roadmap q-summit-home-page-roadmap__roadmap--desktop" />     
        </v-container>
    </div>
</template>

<script>
import QSummitHomePageMountainsWithFlagsDesktop from './background/QSummitHomePageMountainsWithFlagsDesktop.vue'
import QSummitHomePageMountainsWithFlagsMobile from './background/QSummitHomePageMountainsWithFlagsMobile.vue'
import QSummitHomePageRoadmapDesktop from './background/QSummitHomePageRoadmapDesktop.vue'
import QSummitHomePageRoadmapMobile from './background/QSummitHomePageRoadmapMobile.vue'

export default {
  components: {
    QSummitHomePageMountainsWithFlagsDesktop,
    QSummitHomePageMountainsWithFlagsMobile,
    QSummitHomePageRoadmapDesktop,
    QSummitHomePageRoadmapMobile,
  }
}
</script>

<style lang="scss">
.q-summit-home-page-roadmap {
    &__roadmap-wrapper {
        font-size: 0;
        z-index: 1;
        position: relative;

        & * { 
          font-size: normal;
        }
    }

    &__roadmap {
        background: white;
        overflow: hidden;
        
    

        &--mobile {
            border-top-left-radius: 100px;
        }
    }
}
</style>
