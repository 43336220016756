<template>
    <div class="q-summit-home-page">
        <SummitHeader>
            <b style="color: #52CFC1;">Summit exists to accelerate your business results</b>
             by providing what you need to learn, when you need to learn it.
        </SummitHeader>

        <section class="mt-8">
            <v-container>
                <h2 class="q-summit-home-page-h2"> Before You Climb </h2>

                <p>
                    Imagine trying to summit K2 or Mt. Everest by yourself with no “Sherpa” to guide you and no map to tell you which way to go. We created Summit to make sure that you are not climbing alone and to show you exactly what to do next. You will be guided step-by-step through a range of activities to build your knowledge and skills so that you can achieve your business goals.
                </p>

                <p>
                    Can't access? Fast Track is a prerequisite to climb. You will get access to your first mountain once you complete your FastTrack conditioning
                    <a href="https://lsvtlogin.lightspeedvt.com/">here</a>.
                </p>             
            </v-container>
        </section>

        <section>
            <v-container>
                <SummitBirdsBackground />
                <h3 class="q-summit-home-page-h3">
                    There are four mountains you’ll need to Summit to get to the top of the company…
                </h3>
            </v-container>     
        </section>

        <section>
            <SummitHomePageRoadmap class="my-8" />
        </section>

        <section>
            <SummitFooter />
            <SummitHomePageFooterTrees />
        </section>
            
    </div>
</template>

<script>
import SummitHeader from '../../components/summit/homepage/QSummitHomePageHeader.vue'
import SummitFooter from '../../components/summit/homepage/QSummitHomePageFooter.vue'
import SummitBirdsBackground from '../../components/summit/homepage/background/QSummitHomePageBirds.vue'
import SummitHomePageRoadmap from '../../components/summit/homepage/QSummitHomePageRoadmap.vue'
import SummitHomePageFooterTrees from '../../components/summit/homepage/background/QSummitHomePageFooterTrees.vue'


export default {
    components: {
        SummitHeader,
        SummitBirdsBackground,
        SummitHomePageRoadmap,
        SummitFooter,
        SummitHomePageFooterTrees,
        //AccessBlocked
    }
}
</script>


<style lang="scss">
.q-summit-home-page {
    background: white;
}

.q-summit-home-page-h2 {
    font: normal normal 800 24px/22px Montserrat;
    color: #52CFC1;
}

.q-summit-home-page-h3 {
    font: normal normal 800 24px/22px Montserrat;
    color: #005851;
}
</style>